<template>
  <div id="loadingscreen" class="">
    <!-- close -->
    <b-button
      v-if="loadingcompleted"
      variant="primary"
      id="loadingclose"
      class="rounded-circle px-2 right-top"
      @click="closeLoadingScreen()"
    >
      <b-icon icon="x" scale="2"></b-icon>
    </b-button>
    <!-- Desktop Hint -->
    <div id="hint-text" class="left-top" v-if="ismobile">
      <p>
        {{ mobileHint }}
      </p>
    </div>
    <!-- image -->
    <div class="flex-center">
      <img
        class="loadingimage flex-item"
        :src="image"
        alt="loading image"
        v-if="!ismobile"
      />
    </div>
    <!-- animated text slot -->
    <div
      class="dashedborder tolow2"
      v-if="!ismobile && textarray && textarray.length > 0"
    >
      <b-carousel
        id="carousel-loading"
        :interval="4000"
        background="#00000000"
        img-width="1024"
        img-height="1"
      >
        <b-carousel-slide
          img-blank
          img-alt="Blank image"
          v-for="(item, index) in textarray"
          :key="index"
        >
          <h3 class="orange">{{ item }}</h3>
        </b-carousel-slide>
      </b-carousel>
    </div>
    <!-- progress -->
    <div class="flex-center">
      <b-progress
        :value="progressvalue"
        :max="progressmax"
        animated
        class="w-50 mb-2 flex-item"
        height="0.4rem"
      ></b-progress>
      <p v-if="!loadingcompleted" class="flex-item">//loading</p>
      <p v-if="loadingcompleted" class="flex-item">//loading  -  done!</p>
    </div>
    <!-- headline -->
    <div v-if="!ismobile" class="tolow">
      <h4>
        {{ headline }}
        <p class="white">
          <b-icon icon="reply" flip-v scale="1.5"></b-icon> {{ subline }}
        </p>
      </h4>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RefLoadingScreen',
  data () {
    return {
    }
  },
  props: {
    image: { required: true, type: String },
    textarray: { type: Array },
    progresslabel: { required: true, type: String },
    progressvalue: { required: true, type: Number, default: 0 },
    progressmax: { required: true, type: Number },
    headline: { required: true, type: String },
    subline: { required: true, type: String },
    mobileHint: { required: true, type: String },
    ismobile: { required: true, type: Boolean, default: false },
    referenceid: { required: true, type: String },
    loadingcompleted: { required: true, type: Boolean, default: false }
  },
  methods: {
    closeLoadingScreen () {
      document.getElementById('loadingscreen').classList.add('hidden')
      const event = new Event(this.referenceid + '_LoadingScreenClosed')
      window.dispatchEvent(event)
    }
  }
}
</script>

<style lang="scss" scoped>
#loadingscreen {
  min-width: 320px;
  min-height: 100px;
  max-height: 80vh;
  width: 80%;
  margin:  auto;

  background-color: #00060cbf;
  padding: 3%;
  border: solid white 1px;

  z-index: 1000;
  position: absolute;
}
#loadingclose {
  pointer-events: auto;
}
.tolow {
  display: block;
}
.tolow2 {
  display: block;
}
.loadingimage {
  margin-left: 20%;
  margin-right: 20%;
  max-height: 40vh;
  max-width: 50%;
  height: 100%;
  width: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}
.right-top {
  position: absolute;
  top: 40px;
  right: 40px;
  z-index: 25;
}
.left-top {
  position: relative;
  top: 20px;
  left: 40px;
  margin-right: 120px;
  margin-bottom: 40px;
  z-index: 25;
}
.orange {
  color: #ff6600;
  font-family: "Sansation_Regular";
  text-align: center;
}
.dashedborder {
  border-style: dashed none;
  border-color: #00ffd6;
  border-width: 2px;
  margin: 40px 0;
}
.flex-center {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.flex-item {
  align-self: center;
}
.hidden {
  display: none;
}
h3 {
  font-size: 2rem !important;
  line-height: 1;
}
h4 {
  color: #00ffd6;
  text-align: left;
  padding: 0 10%;
  font-family: "Sansation_Italic";
  margin-top: 3%;
}
p {
  font-size: 1rem;
}
.white {
  color: white;
  padding: 5px 10%;
}
.btn-primary {
  color: black;
  background-color: #00ffd6;
  border-color: #00ffd6;
}
.btn-primary:hover {
  color: #212529;
  background-color: #00977e;
  border-color: #00977e;
}
.btn-primary:focus,
.btn-primary.focus {
  box-shadow: none;
  color: black;
  background-color: #00ffd6;
  border-color: #00ffd6;
}
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #212529;
  background-color: #00977e;
  border-color: #00977e;
}
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem #00977e77;
  color: black;
  background-color: #00ffd6;
  border-color: #00ffd6;
}
@media (max-width: 992px) {
  h3 {
    font-size: 1.75rem !important;
  }
}
@media (max-width: 768px) {
  h3 {
    font-size: 1.4rem !important;
    font-size: calc(
      0.96rem + (2 - 0.96) * ((100vw - 20rem) / (48 - 20))
    ) !important;
  }

  #loadingscreen {
    min-width: 250px;
  }
}
@media (max-width: 650px) {
  h3 {
    font-size: 1.2rem !important;
    font-size: calc(
      0.76rem + (2 - 0.76) * ((100vw - 20rem) / (48 - 20))
    ) !important;
  }
}
@media (max-width: 600px) {
  .tolow {
    display: none;
  }
}
@media (max-height: 750px) {
  .tolow {
    display: none;
  }
}
@media (max-height: 550px) {
  .tolow2 {
    display: none;
  }
}
</style>
